const routers = [
    // 登录页面
    {
        path: '/',
        name: 'login',
        component: (resolve) => require(['./views/login.vue'], resolve)
    },
    // 授权页面
    {
        path: '/authorization/:idStr',
        name: 'authorization',
        component: (resolve) => require(['./views/authorization.vue'], resolve)
    },

    //主页---------------------------------------------------------------
    {
        path: '/index',
        name: 'index',
        component: (resolve) => require(['./views/index.vue'], resolve),
        children: [

            {
                path: '/statistics',
                name: 'statistics',
                meta: [{ title: '平台管理', name: '' }, { title: '运营统计', name: 'statistics' }],
                component: (resolve) => require(['./views/platform/statistics.vue'], resolve)
            },
            {
                path: '/headUrlList',
                name: 'headUrlList',
                meta: [{ title: '平台管理', name: '' }, { title: '头图管理', name: 'headUrlList' }],
                component: (resolve) => require(['./views/platform/headUrlList.vue'], resolve)
            },
            {
                path: '/headUrlAdd',
                name: 'headUrlAdd',
                meta: [{ title: '平台管理', name: '' }, { title: '头图管理', name: 'headUrlList' }, { title: '新增头图', name: 'headUrlAdd' }],
                component: (resolve) => require(['./views/platform/headUrlAdd.vue'], resolve)
            },
            {
                path: '/headUrlUpdate',
                name: 'headUrlUpdate',
                meta: [{ title: '平台管理', name: '' }, { title: '头图管理', name: 'headUrlList' }, { title: '修改头图', name: 'headUrlAdd' }],
                component: (resolve) => require(['./views/platform/headUrlAdd.vue'], resolve)
            },
            {
                path: '/scenicSpotList',
                name: 'scenicSpotList',
                meta: [{ title: '平台管理', name: '' }, { title: '景点管理', name: 'scenicSpotList' }],
                component: (resolve) => require(['./views/platform/scenicSpotList.vue'], resolve)
            },
            {
                path: '/scenicSpotAdd',
                name: 'scenicSpotAdd',
                meta: [{ title: '平台管理', name: '' }, { title: '景点管理', name: 'headUrlList' }, { title: '新增景点', name: 'scenicSpotAdd' }],
                component: (resolve) => require(['./views/platform/scenicSpotAdd.vue'], resolve)
            },
            {
                path: '/scenicSpotUpdate',
                name: 'scenicSpotUpdate',
                meta: [{ title: '平台管理', name: '' }, { title: '景点管理', name: 'headUrlList' }, { title: '修改景点', name: 'scenicSpotUpdate' }],
                component: (resolve) => require(['./views/platform/scenicSpotAdd.vue'], resolve)
            },

            {
                path: '/userList',
                name: 'userList',
                meta: [{ title: '用户管理', name: '' }, { title: '用户列表', name: 'userList' }],
                component: (resolve) => require(['./views/user/userList.vue'], resolve)
            },
            {
                path: '/goodsShareList',
                name: 'goodsShareList',
                meta: [{ title: '商品管理', name: '' }, { title: '分享', name: 'goodsShareList' }],
                component: (resolve) => require(['./views/goods/goodsShareList.vue'], resolve)
            },
            {
                path: '/goodsObjectList',
                name: 'goodsObjectList',
                meta: [{ title: '商品管理', name: '' }, { title: '商品', name: 'goodsObjectList' }],
                component: (resolve) => require(['./views/goods/goodsObjectList.vue'], resolve)
            },
            {
                path: '/goodsActivityList',
                name: 'goodsActivityList',
                meta: [{ title: '商品管理', name: '' }, { title: '活动', name: 'goodsActivityList' }],
                component: (resolve) => require(['./views/goods/goodsActivityList.vue'], resolve)
            },
            {
                path: '/orderList',
                name: 'orderList',
                meta: [{ title: '订单管理', name: '' }, { title: '订单列表', name: 'orderList' }],
                component: (resolve) => require(['./views/order/orderList.vue'], resolve)
            },

            {
                path: '/adminList',
                name: 'adminList',
                meta: [{ title: '系统管理', name: '' }, { title: '管理员列表', name: 'adminList' }],
                component: (resolve) => require(['./views/system/adminList.vue'], resolve)
            },

        ]

    }
]
export default routers