import Vue from 'vue';
import App from './App.vue'
import ViewUI from 'view-design';
import VueRouter from 'vue-router';
import Routers from './router';
import Util from './libs/util';
import 'view-design/dist/styles/iview.css';
import './styles/theme.less';

Vue.use(VueRouter);
Vue.use(ViewUI);

// 路由配置
const RouterConfig = {
  mode: 'history',
  routes: Routers
};
const router = new VueRouter(RouterConfig);

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  next();
});

router.afterEach((to, from, next) => {
  ViewUI.LoadingBar.finish();
  window.scrollTo(0, 0);
});

new Vue({
  el: '#app',
  router: router,
  render: h => h(App)
});

Vue.prototype.MyUtil = Util;
Vue.prototype.getKey = function (key) {
  return JSON.parse(localStorage.getItem(key));
};
Vue.prototype.setKey = function (key, value) {
  if (Util.notNull(value)) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, null);
  }

};