import axios from 'axios';
import { Message } from 'view-design';
import { uuid } from 'vue-uuid';

// 运行环境---------------------------------------------------------
// 开发环境
let title = "百家连营（开发）";
let ajaxUrl = "http://10.1.10.99:8092/";
ajaxUrl = "http://183.220.113.73:8092";//本地外网
// 测试环境
if (process.env.NODE_ENV == 'test') {
    title = "百家连营（测试）";
    ajaxUrl = "https://qiyouji.iot366.cn:9089/";
}
// 正式环境
if (process.env.NODE_ENV == 'production') {
    title = "百家连营";
    ajaxUrl = "https://wei-photo.bolexiansheng.com/";
    title = "酉良严选";
    ajaxUrl = "https://bjly.hitcx.com/bjlyapi/";
}
// 网页标签title--------------------------------------------------------
window.document.title = title;
Message.config({
    duration: 3
});

// 自定义函数--------------------------------------------------------
let util = {};
// 网页标签title--------------------------------------------------------
util.title = title;
// 接口访问地址--------------------------------------------------------
util.ajaxUrl = ajaxUrl;
// 文件访问地址--------------------------------------------------------
// util.fileUrl = "https://bai-jia-lian-ying-1.oss-cn-shenzhen.aliyuncs.com/";
util.fileUrl = "https://cd-baijialianying.oss-cn-shenzhen.aliyuncs.com/";

//设置token--------------------------------------------------------
util.setToken = function (param) {
    if (param) {
        localStorage.setItem('tokenKey', param)
    }
};
//获取token--------------------------------------------------------
util.getToken = function () {
    return localStorage.getItem('tokenKey')
};
// ajax请求统一处理--------------------------------------------------------
util.ajax = function () {
    var instance = axios.create({
        baseURL: ajaxUrl,
        timeout: 50000,
        xhrFields: { withCredentials: true },
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
    // 请求拦截器
    instance.interceptors.request.use(
        function (config) {
            let token = util.getToken();
            if (token) {
                config.headers.common['token'] = token;
            }
            return config;
        },
        function (err) {
            return Promise.reject(err);
        }
    );
    // 响应拦截器
    instance.interceptors.response.use(
        function (res) {
            //1，响应为空时，抛出错误
            if (undefined == res || null == res) {
                Message.warning("请求失败，请稍后重试");
                throw new Error("请求失败，请稍后重试");
            }
            //2，响应数据为空时，返回{}
            let data = res.data;
            if (undefined == data || null == data) {
                return {};
            }
            let code = data.code;
            let msg = data.msg;
            let obj = data.obj;
            //3，code为200时，返回obj
            if (200 == code) {
                //obj为空时，设为{}
                if (undefined == obj || null == obj) {
                    obj = {};
                }
                return obj;
            }
            //4，code为101时，跳转登录页面，抛出错误
            if (101 == code) {
                setTimeout(function () {
                    window.location.href = '/';
                }, 2000);
                throw new Error(msg);
            }
            //5，code为其他时，提示错误信息，抛出错误
            Message.warning(msg);
            throw new Error(msg);
        },
        function (err) {
            return Promise.reject(err);
        }
    );
    return instance;
};

// 判断为空，undefined，null，空白--------------------------------------------------------
util.isNull = function (obj) {
    if (obj == undefined) {
        return true;
    }
    if (obj == null) {
        return true;
    }
    obj = obj.toString().replace(/\s*/g, "");
    if (obj == "") {
        return true;
    }
    return false;
};
// 判断非空，!undefined，!null，!空白--------------------------------------------------------
util.notNull = function (obj) {
    if (obj == undefined) {
        return false;
    }
    if (obj == null) {
        return false;
    }
    obj = obj.toString().replace(/\s*/g, "");
    if (obj == "") {
        return false;
    }
    return true;
};
//时间戳格式化，默认格式(yyyy-MM-dd HH:mm:ss)--------------------------------------------------------
util.dateToString = function (time, pattern) {
    if (util.isNull(time)) {
        return null;
    }
    if (util.isNull(pattern)) {
        pattern = "yyyy-MM-dd HH:mm:ss";
    }
    var oldTime = null;
    if (time instanceof Date) {
        oldTime = time;
    } else if (/^[0-9]+.?[0-9]*/.test(time)) {
        oldTime = new Date(time);
    } else {
        return time;
    }
    var yyyy = oldTime.getFullYear();
    var MM = oldTime.getMonth() + 1;
    var dd = oldTime.getDate();
    var HH = oldTime.getHours();
    var mm = oldTime.getMinutes();
    var ss = oldTime.getSeconds();
    var SSS = oldTime.getMilliseconds();
    if (MM < 10) {
        MM = "0" + MM;
    }
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (HH < 10) {
        HH = "0" + HH;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    if (ss < 10) {
        ss = "0" + ss;
    }
    pattern = pattern.replace(/y+/, yyyy);
    pattern = pattern.replace(/M+/, MM);
    pattern = pattern.replace(/d+/, dd);
    pattern = pattern.replace(/H+/, HH);
    pattern = pattern.replace(/m+/, mm);
    pattern = pattern.replace(/s+/, ss);
    pattern = pattern.replace(/S+/, SSS);
    return pattern;
};
//复制对象，解除双向绑定
util.copy = function (obj) {
    if (util.isNull(obj)) {
        return null;
    }
    return JSON.parse(JSON.stringify(obj))
}
// uuid--------------------------------------------------------
util.uuid = function () {
    return uuid.v1();
};

/**
 * 展示原图，点击图片时调用此方法
 */
util.showBigImg = function (src) {
    var recordDom = document.getElementById("public-big-image");
    if (recordDom != null) {
        document.body.removeChild(recordDom);
    } else {
        var div = document.createElement("div");
        div.id = "public-big-image";
        div.title = "点击关闭图片";
        div.style.cursor = 'pointer';
        div.style.zIndex = 9998;
        div.style.position = 'fixed';
        div.style.margin = 'auto';
        div.style.top = 0;
        div.style.bottom = 0;
        div.style.left = 0;
        div.style.right = 0;
        div.style.height = '100%';
        div.style.width = '100%';
        div.style.opacity = '0.5';
        div.style.backgroundColor = '#666666';
        div.onclick = function () {
            document.body.removeChild(div);
            document.body.removeChild(img);
        };
        var img = document.createElement("img");
        img.id = "public-big-image";
        img.title = "点击关闭图片";
        img.style.cursor = 'pointer';
        img.style.zIndex = 9999;
        img.style.position = 'fixed';
        img.style.margin = 'auto';
        img.style.top = 0;
        img.style.bottom = 0;
        img.style.left = 0;
        img.style.right = 0;
        img.style.maxHeight = '100%';
        img.style.maxWidth = '100%';
        img.src = src;
        img.onclick = function () {
            document.body.removeChild(div);
            document.body.removeChild(img);
        };
        document.body.appendChild(div);
        document.body.appendChild(img);
    }
};
export default util;